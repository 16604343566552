import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import BeforeSinglePost from "../components/templates/beforeSingle/BeforeSinglePost"

const BeforeSingle = props => {
  const { before } = props.data
  const prevBefore = props.pageContext.prev
  const nextBefore = props.pageContext.next
  return (
    <Layout>
      <Seo title="Before Single" />
      <BeforeSinglePost
        post={before}
        prevBefore={prevBefore}
        nextBefore={nextBefore}
      />
    </Layout>
  )
}

export const query = graphql`
  query beforePostQuery($slug: String!) {
    before: wpBeforeAfterSingle(slug: { eq: $slug }) {
      postTypeBeforeAndAfter {
        heroImage {
          altText
          sourceUrl
          localFile {
            url
            childImageSharp {
              gatsbyImageData(width: 2500)
            }
          }
        }

        heroLogo {
          altText
          sourceUrl
          localFile {
            url
            childImageSharp {
              gatsbyImageData(width: 1500)
            }
          }
        }

        readyHeroTitle
        readyHeroContent
        readyHeroButtonText
        readyHeroButtonSlug
        readyHeroImage {
          altText
          sourceUrl
          localFile {
            url
            childImageSharp {
              gatsbyImageData(width: 2500)
            }
          }
        }

        story
        imageBefore {
          altText
          sourceUrl
          localFile {
            url
            childImageSharp {
              gatsbyImageData(width: 1500)
            }
          }
        }

        imageAfter {
          altText
          sourceUrl
          localFile {
            url
            childImageSharp {
              gatsbyImageData(width: 1500)
            }
          }
        }
      }
      date
      title
      slug
      id
    }
  }
`

export default BeforeSingle
