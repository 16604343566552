import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { B1Blue, medWrapper } from "../../../styles/helpers"

const PostNav = ({ prevPost, nextPost }) => {
  return (
    <PostNavStyled>
      <div className="wrapper">
        <nav>
          {nextPost && (
            <Link to={`/before-and-after/${nextPost}`}>
              <span>&lt; </span>
              Next Case
            </Link>
          )}
          <Link to="/before-and-after">Before + After Home Page</Link>
          {prevPost && (
            <Link to={`/before-and-after/${prevPost}`}>
              Previous Case
              <span>&gt; </span>
            </Link>
          )}
        </nav>
      </div>
    </PostNavStyled>
  )
}

const PostNavStyled = styled.div`
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .wrapper {
    ${medWrapper};
    max-width: 95rem !important;
  }

  nav {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    a {
      ${B1Blue};
      font-weight: bold;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
`

export default PostNav
