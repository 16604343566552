import React from "react"

import Hero from "../common/Hero"
import Story from "./Story"
import PostNav from "./PostNav"
import ReadyHero from "../common/ReadyHero"

const BeforeSinglePost = ({ post, prevBefore, nextBefore }) => {
  return (
    <div>
      <Hero data={post.postTypeBeforeAndAfter} />
      <Story data={post} />
      <PostNav prevPost={prevBefore} nextPost={nextBefore} />
      <ReadyHero data={post.postTypeBeforeAndAfter} />
    </div>
  )
}

export default BeforeSinglePost
